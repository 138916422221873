<script>
import transConfig from "@/core/config/transaction.config";
import moment from 'moment-timezone';
import userConfig from "@/core/config/user.config";

import Vue from "vue";
import Notifications from "vue-notification";
Vue.use(Notifications);
export default {
  methods: {
    createLinkPage(pageNum) {
      return `?page=${pageNum}`;
    },
    getTextStatus(status) {
        let statusText = transConfig.TEXT_STATUS.find(item => item.value === status);
        return statusText ? statusText.title : status;
    },
    getColorStatus(status, type = "TRANSACTION") {
      let _class = "label font-weight-bold label-lg label-inline ";
      if (type === "TRANSACTION") {
        switch (status) {
          case transConfig.STATUS_SUCCESS:
          case transConfig.STATUS_PAYMENT_SUCCESS:
          case transConfig.STATUS_ACTION_SUCCESS:
            _class += "label-light-success";
            break;
          case transConfig.STATUS_CREATE:
            _class += "label-light-primary";
            break;
          case transConfig.STATUS_PENDING:
            _class += "label-light-info";
            break;
          case transConfig.STATUS_FAIL:
            _class += "label-light-danger";
            break;
          default:
            _class += "";
            break;
        }
        return _class;
      } else if (type === "WHITE_LIST") {
        if (status === transConfig.STATUS_ACTIVE) {
          _class += "label-light-success";
        } else {
          _class += "";
        }
        return _class;
      } else if (type === "USER") {
        if (status === userConfig.ACTIVE) {
          _class += "label-light-success";
        } else {
          _class += "";
        }
        return _class;
      }
    },

    getCurrentDay() {
      let date = new Date();
      return this.formatYmd(date);
    },
    getCurrentDateTime() {
      let date = new Date();
      return this.formatYmd(date) + " 00:00:00";
    },
    getYesterDateTime() {
      let date = new Date();
      return this.formatYmd(date) + " 23:59:59";
    },

    getYesterday() {
      let date = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
      return this.formatYmd(date);
    },

    getLastMonth() {
      let date = new Date(new Date().valueOf() - 30 * 1000 * 60 * 60 * 24);
      return this.formatYmd(date);
    },

    getFirstDayOfMonth() {
      let date = new Date();
      let firstOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      return this.formatYmd(firstOfMonth);
    },

    formatYmd(date) {
      let dateA = new Date(date.setHours(23, 59, 59, 0));
      return dateA.toISOString().split("T")[0];
    },

    observerToData(value) {
      return JSON.parse(JSON.stringify(value));
    },

    downloadTxtFile(fileName, text) {
      var el = document.createElement("a");
      el.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      el.setAttribute("download", fileName);

      el.style.display = "none";
      document.body.appendChild(el);

      el.click();

      document.body.removeChild(el);
    },

    downloadCsvFile(fileName, data) {
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();

      document.body.removeChild(fileLink);
    },

    downloadXlsxFile(fileName, data) {
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    showSuccess(content, title = "Thành công") {
      this.makeToastVariant(title, content, "success");
    },

    showError(content, title = "Có lỗi xảy ra") {
      this.makeToastVariant(title, content, "danger");
    },

    // variant : primary, secondary, danger, warning, success, info
    makeToastVariant(title, content, variant = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant: variant,
        solid: true,
      });
    },

    //
    notifyAlert(type = "warn", message = "Có lỗi xẩy ra") {
      Vue.notify({
        group: "foo",
        type: type,
        title: message,
      });
    },

    // remove validate null
    removeValidateNull(params = null) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    showLoadingButton(btn) {
      btn.classList.add("spinner", "spinner-light", "spinner-right");
    },

    hideLoadingButton(btn) {
      btn.classList.remove("spinner", "spinner-light", "spinner-right");
    },

    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    getDatesInRange(startDate, endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);

      const date = new Date(start.getTime());
      const dates = [];
      while (date <= end) {
        dates.push(new Date(date).toISOString().split("T")[0]);
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },

    dateIsValid(dateStr) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;

      if (dateStr.match(regex) === null) {
        return false;
      }

      const date = new Date(dateStr);

      const timestamp = date.getTime();

      if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
      }

      return date.toISOString().startsWith(dateStr);
    },
    formatTimeLogUnix(date) {
      if (date == 'null' || date == undefined) {
        return "-";
      }
      return moment(date).format('HH:mm DD/MM/YYYY');
    },
    numberFormat(value) {
      if (typeof value != "number") {
        return value;
      }
      var number = parseInt(value);
      if (isNaN(number) || number == null) {
        return 0;
      }
      var formatter = new Intl.NumberFormat("it-IT");
      return formatter.format(number);
    },
    formatMoneyVn(value) {
      var formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
      });
      return formatter.format(value);
    },
    handleKeyDown(event) {
      if (event.key === "-" || event.key === "Subtract") {
        // Ngăn không cho phép nhập ký tự "-" hoặc "Subtract"
        event.preventDefault();
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    limitString60(value) {
      if (value.length < 60) {
        return value;
      } else {
        return value.substring(0, 60) + "...";
      }
    },
    formatDateFromTime(value){
        return moment.unix(value).format("DD/MM/YYYY HH:mm");
    } ,
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
    },
    formatDateTime(value) {
      return String(value) ? moment(String(value)).format("DD-MM-YYYY HH:mm:ss") : '---';
    },
    firstDayOfMonth() {
      return moment().startOf('month').format('YYYY-MM-DD');
    },
    lastDayOfMonth() {
      return moment().endOf('month').format('YYYY-MM-DD');
    },
    formatArrayKeyValue(array) {
      const data = {};
      if (!Array.isArray(array)) {
        return array;
      }
      array.forEach((currentElement) => {
        data[currentElement.code] = currentElement;
      });
      return data;
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    reverseMoney(value) {
      return value.replace('đ', '');
    },
    numbersOnly(evt, allowPaste = false) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;

      if (allowPaste !== false) {
        if (evt.ctrlKey && charCode === 86) {
          return true;
        }
      }

      if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode === 8) {
        // 0-9 only
        return true;
      } else {
        evt.preventDefault();
      }
    }
  },
};
</script>
