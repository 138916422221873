const ACTIVE = "ACTIVE";
const UN_ACTIVE = "UN_ACTIVE";


const TEXT_STATUS = [
  { value: ACTIVE, title: "Kích hoạt" },
  { value: UN_ACTIVE, title: "Chưa kích hoạt" }
];

export default {
  TEXT_STATUS: TEXT_STATUS,
  ACTIVE: ACTIVE,
  UN_ACTIVE: UN_ACTIVE
};
