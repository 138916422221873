const STATUS_CREATE_ERROR = "CREATE_ERROR";
const STATUS_CREATE = "CREATE";
const STATUS_CREATE_SUCCESS = "CREATE_SUCCESS";
const STATUS_PENDING = "PENDING";
const STATUS_FAIL = "FAIL";
const STATUS_CANCEL = "CANCEL";
const STATUS_SUCCESS = "SUCCESS";
const STATUS_REVERSED = "REVERSED";
const STATUS_REPLACE = "CREATE_REPLACE";
const STATUS_PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
const STATUS_ACTION_SUCCESS = 1;
const STATUS_ACTION_FAIL = -1;
const STATUS_ACTION_PENDING = 0;
const STATUS_ORDER_SUCCESS = "SUCCESS";
const STATUS_WAIT_TO_REVIEW = "WAIT_TO_REVIEW";
const STATUS_WAIT_TO_REFUND = "WAIT_TO_REFUND";
const STATUS_ACTIVE = "Đã kích hoạt";
const STATUS_REFUNDED = "Đã hoàn tiền";
const STATUS_ORDER_WAIT_TO_REVIEW = "WAIT_TO_REVIEW";
const STATUS_ORDER_WAIT_TO_REFUND = "WAIT_TO_REFUND";
const STATUS_ORDER_PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

const STATUS_FAIL_TEXT = "Thất bại";
const STATUS_CANCEL_TEXT = "Hủy";


const TRANSACTION_ACTION_WITHDRAW = "WITHDRAW";

const TEXT_STATUS = [
  { value: STATUS_CREATE_ERROR, title: "Tạo lỗi" },
  { value: STATUS_CREATE, title: "Mới tạo" },
  { value: STATUS_CREATE_SUCCESS, title: "Tạo thành công" },
  { value: STATUS_PENDING, title: "Đang chờ" },
  { value: STATUS_FAIL, title: "TT Lỗi" },
  { value: STATUS_CANCEL, title: "Huỷ GD" },
  { value: STATUS_SUCCESS, title: "Thành công" },
  { value: STATUS_REPLACE, title: "Đã tạo GD khác" },
  { value: STATUS_REVERSED, title: "Đã đảo" },
  { value: STATUS_PAYMENT_SUCCESS, title: "Thanh toán thành công" },
  { value: STATUS_REFUNDED, title: "Đã hoàn tiền" }
];

export default {
  TEXT_STATUS: TEXT_STATUS,
  STATUS_CREATE_ERROR: STATUS_CREATE_ERROR,
  STATUS_CREATE: STATUS_CREATE,
  STATUS_CREATE_SUCCESS: STATUS_CREATE_SUCCESS,
  STATUS_PENDING: STATUS_PENDING,
  STATUS_FAIL: STATUS_FAIL,
  STATUS_CANCEL: STATUS_CANCEL,
  STATUS_SUCCESS: STATUS_SUCCESS,
  STATUS_REVERSED: STATUS_REVERSED,
  STATUS_REPLACE: STATUS_REPLACE,
  STATUS_REFUNDED: STATUS_REFUNDED,
  STATUS_PAYMENT_SUCCESS: STATUS_PAYMENT_SUCCESS,
  STATUS_ACTION_SUCCESS: STATUS_ACTION_SUCCESS,
  STATUS_ACTION_FAIL: STATUS_ACTION_FAIL,
  STATUS_ACTION_PENDING: STATUS_ACTION_PENDING,
  STATUS_WAIT_TO_REVIEW: STATUS_WAIT_TO_REVIEW,
  STATUS_WAIT_TO_REFUND: STATUS_WAIT_TO_REFUND,
  STATUS_ORDER_WAIT_TO_REVIEW: STATUS_ORDER_WAIT_TO_REVIEW,
  STATUS_ORDER_WAIT_TO_REFUND: STATUS_ORDER_WAIT_TO_REFUND,
  STATUS_ORDER_PAYMENT_SUCCESS: STATUS_ORDER_PAYMENT_SUCCESS,
  STATUS_FAIL_TEXT: STATUS_FAIL_TEXT,
  STATUS_CANCEL_TEXT: STATUS_CANCEL_TEXT,
  STATUS_ACTIVE: STATUS_ACTIVE,
  STATUS_ORDER_SUCCESS: STATUS_ORDER_SUCCESS,
  TRANSACTION_ACTION_WITHDRAW:TRANSACTION_ACTION_WITHDRAW
};
